import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import FAQItems from "./FAQItems"

const FAQSection = () => {
  const data: {
    mdx: {
      frontmatter: {
        questions: {
          answer: string
          question: string
        }[]
      }
    }
  } = useStaticQuery(graphql`
    {
      mdx(frontmatter: { templateKey: { eq: "FAQ" } }) {
        frontmatter {
          questions {
            answer
            question
          }
        }
      }
    }
  `)
  return (
    <section className="faq section-padding">
      <div className="container">
        <h1 className="heading heading--blog--1 heading--svg-underline">
          Aide Particuliers
        </h1>
        <FAQItems questionItems={data.mdx.frontmatter.questions} />
      </div>
    </section>
  )
}

export default FAQSection
