import React from "react"

import Layout from "../components/Layout"
import FAQSection from "../components/pages/faq/FAQSection"
import SEO from "../components/Seo"

const FAQ = () => (
  <Layout activePage="faq">
    <SEO
      title="FAQ"
      description="La Consigne GreenGo est une app' mobile permettant d’emprunter facilement des emballages réutilisables et consignés dans tout type de structures : restaurants, cafétérias d’entreprises et grande distribution."
      keywords={["faq", "consigne", "emballage", "collecteur", "restauration"]}
      robots="none" // TODO: remove this when FAQ is ready
    />
    <FAQSection />
  </Layout>
)

export default FAQ

