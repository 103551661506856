import React from "react"
import "./FAQItem.scss"
import { RenderMarkdown } from "../../RenderMarkdown"
import { MDXProvider } from "@mdx-js/react"

interface IFAQItem {
  question: string
  answer: string
  opened: boolean
}

const FAQItem: React.FunctionComponent<IFAQItem> = props => {
  return (
    <details open={props.opened} className="faq-item">
      <summary>{props.question}</summary>
      <div className="markdown">
        <MDXProvider components={{}}>
          <RenderMarkdown markdown={props.answer} />{" "}
        </MDXProvider>
      </div>
    </details>
  )
}

export default FAQItem
