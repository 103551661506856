import React, { useState } from "react"
import FAQItem from "./FAQItem"
import "./FAQItems.scss"

interface IFAQItems {
  questionItems: { question: string; answer: string}[];
}

const FAQItems: React.FunctionComponent<IFAQItems> = props => {
  const [openedIndex, setOpenedIndex] = useState<number | undefined>(undefined)
  const onClick = (index: number) => {
    setOpenedIndex(index)
  }
  return (
      <ul className="faq-items">
        {props.questionItems.map((question, index) => {
            const isOpened = openedIndex === index;
            // we force the rendering on click, otherwise the component does not immediately re-render despite props change
            const itemKey = `question-${index}-${isOpened}`
            return (
                <li key={itemKey} onClick={() => onClick(index)}>
                  <FAQItem
                    question={question.question}
                    answer={question.answer}
                    opened={isOpened}
                  />
                </li>
            );
        })}
      </ul>
  );
}

export default FAQItems
